import styled from "styled-components";

interface Props {
	isActive: boolean;
}

export const Span = styled.div<Props>`
	white-space: nowrap;
	@media screen and (max-width: 820px){
		display: ${({ isActive }: Props) => isActive ? 'flex' : 'none'}!important;
	}
`;
