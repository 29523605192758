import { Desktop } from "./style";
import desktopIcon from "../../assets/logo-star.svg";

export default function Logo() {
	return (
		<>
			<Desktop src={desktopIcon} />
		</>
	)
}
