import { HeaderSite, MobileSeparator, Separator } from "./styles";
import { HeaderContainer } from "./styles";
import { MenuDesktop } from "./styles";
import { DesktopGradientLine } from "./styles";
import { MobileGradientLine } from "./styles";
import { useEffect, useState } from "react";
import Logo from "../Logo";
import { IStep, Steps } from '../../domain/index';
import Step from "../Step";
import MobileLogo from "../Logo/mobile";


const Header = () => {
  const path = location.pathname;
  const [steps, setSteps] = useState<Array<IStep>>(Steps.start(path));


  useEffect(() => {
    document.getElementById(
      "single-spa-application:@einstein/payment-header"
    ).style.marginBottom = "64px";

    document.addEventListener('@einstein-payment-header/change-step', (e: CustomEvent) => {
      setSteps([]);
      setSteps(Steps.start(e.detail));
    });
  }, []);

  const handleStep = (step: IStep) => {
    return (<Step key={step.baseUrl} step={step} />);
  }

  return (
    <HeaderSite>
      <MobileLogo />
      <MobileGradientLine />
      <MobileSeparator />
      <HeaderContainer>
        <Logo />
        <Separator />
        <MenuDesktop>
          {steps.map(handleStep)}
        </MenuDesktop>
      </HeaderContainer>
      <DesktopGradientLine />
    </HeaderSite>
  );
};

export default Header;
