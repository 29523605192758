import { IStep } from "../../domain";
import { Circle } from "../Header/styles";
import { IconCheck, ArrowBack } from "../../assets/icons";
import { Span } from "./style";

interface Props {
	step: IStep;
}

export default function Step({ step }: Props) {
	return (
		<>
			{step.position > 1 && (<div className={step.isActive ? 'arrow actual' : 'arrow past'}><ArrowBack /></div>)}
			<button style={{ border: 'none', maxWidth: '224px' }}>
				<div className={step.isActive ? 'menu-item actual' : step.hasPassed ? 'menu-item past' : 'menu-item'}>
					<Circle >{step.hasPassed ? <IconCheck /> : step.position}</Circle>
					{<Span isActive={step.isActive}>{step.description}</Span>}
				</div>
			</button>
		</>
	);
}
