import styled from "styled-components";
import { Container } from "../../styles/GlobalStyle";

export const HeaderSite = styled.header`
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 10;
  top: 0;
  left: 0;
  @media screen and (max-width: 820px){
    border-radius: 7px;
  }
`;

export const HeaderContainer = styled(Container)`
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  height: 63px;
  .label {
    font-weight: 600;
    color: #004f92;
    font-size: 17px;
    border-left: solid 1px #004f9269;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 1rem;
    margin-left: 1rem;
  }
  svg {
    height: 23px;
  }

  @media screen and (max-width:920px) {
    background-color: #F0F2F4;
    padding: 16px;
    width: 100%;
  }
`;

export const MenuDesktop = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 320px;
  button {
    background-color: #fff;
    pointer-events: none;
  }
  .menu-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #b2cade;
    font-weight: 600;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    @media screen and (max-width: 820px){
      padding-right: 0;
      padding-left: 0;
    }
  }
  .actual {
    color: #004f92;
    path {
      stroke: #004f92 !important;
    }
  }
  .past {
    color: #00beb1;
    path {
      stroke: #01b092 !important;
    }
  }
  circle {
    background: #e5f9f7;
    color: #00beb1;
    path {
      stroke: #00beb1 !important;
    }
  }
  .arrow {
    transform: rotate(90deg);
    path {
      stroke: #b2cade;
    }
  }

  @media screen and (max-width: 920px){
      background-color: white;
      width: 100%;
      height: 60px;
      border-radius: 8px;
  }
  
  @media screen and (min-width: 990px){
      margin-right: 100px;
  }
`;

export const Circle = styled.div`
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: #f0f2f4;
  color: #b2cade;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  @media screen and (max-width:680px) {
    margin-right: 5px;
    margin-left: 5px;
  }
`;

export const DesktopGradientLine = styled.div`
  background-image: linear-gradient(
    to right,
    #ecb561 0%,
    #de656b 30%,
    #4595c4 60%,
    #3ad5ca 100%
  );
  height: 8px;
  @media screen and (max-width:920px) {
    display: none;
  }
`;

export const MobileGradientLine = styled.div`
  background-image: linear-gradient(
    to right,
    #ecb561 0%,
    #de656b 30%,
    #4595c4 60%,
    #3ad5ca 100%
  );
  height: 8px;
  @media screen and (min-width:920px) {
    display: none;
  }
`;

export const Separator = styled.div`
  border-right: solid 1px #005a9c;
  height: 35px;
  margin-right: 7px;
  margin-left: 17px;
  @media screen and (max-width:920px) {
    display: none;
  }
`;

export const MobileSeparator = styled.div`
  display: flex;
  background-color: #F0F2F4;
  height: 15px;
  @media screen and (min-width:920px) {
    display: none;
  }
`;
