const defaultSteps = [
	{
		description: "Login ou Cadastro",
		isActive: false,
		baseUrl: "/app-payment/login",
		position: 1,
		hasPassed: false
	},
	{
		description: "Comprador",
		isActive: false,
		baseUrl: "/app-payment/buyer",
		position: 2,
		hasPassed: false
	},
	// TODO: ADD `BENEFICIARY` STEP (FAMILY PLAN)
	{
		description: "Pagamento",
		isActive: false,
		baseUrl: "/app-payment/payment",
		position: 3,
		hasPassed: false
	}
];

export interface IStep {
	description: string;
	isActive: boolean;
	baseUrl: string;
	position: number;
	hasPassed: boolean;
}

export class Steps {
	public static start(url?: string): Array<IStep> {
		const instance = new Steps();
		const current = instance.findCurrentIndex(url ?? "");
		const steps = instance.steps;

		if (current > 0) {
			let i = 0;
			while (i < current && steps[i]) {
				steps[i].isActive = false;
				steps[i].hasPassed = true;
				i++;
			}
		}

		steps[current].isActive = true;
		return steps;
	}

	private readonly steps: IStep[] = defaultSteps;

	private constructor() {}

	private findCurrentIndex(url: string): number {
		const index = this.steps.findIndex((step) => step.baseUrl === url);
		if (index === -1) return 0;
		return index;
	}
}
