import styled from "styled-components";

export const Desktop = styled.img`
	@media screen and (max-width: 920px){
		display: none;
	}
`;

export const Mobile = styled.img`
	@media screen and (min-width: 920px){
		display: none;
	}
`;

export const MobileContainer = styled.div`
	display: flex; 
	width: 100%; 
	background-color: white;
	padding: 9px;
	justify-content: center;
	@media screen and (min-width: 920px){
			display: none;
	}
`