import { Mobile, MobileContainer } from "./style";
import File from '../../assets/logo-star.svg';

export default function MobileLogo() {
	return (
		<MobileContainer>
			<Mobile src={File} alt="logo einstein" />
		</MobileContainer>
	)
}
