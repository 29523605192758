import styled, { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    .react-multi-carousel-dot-list {
        position: relative !important;
    }
    :root{
        font-size: 1rem;
    }

    *{
        margin:0;
        padding: 0;
        box-sizing: border-box;
    }

    body, 
    html{
        font-family: 'Montserrat', sans-serif;
    }

    button{
        outline: 0;
        border: 0;       
    }

    a, 
    a:visited, 
    a:focus, 
    a:hover{
        text-decoration: none;
        color: inherit;
    }

    ul{
        list-style: none;
    }

`;

export const TitleH3 = styled.h3`
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 12px;
`;
export const TitleH2 = styled.h2`
  margin-bottom: 25px;
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Container = styled.div`
  width: 95%;
  margin: 0 auto;
`;

// export const Content = styled.main`
//   min-height: 100vh;
//   padding-top: 66px;
// `;

export const Content = styled.div`
  background: #f0f2f4;

  .header header {
    left: 0px !important;
  }
`;
